import React from "react";

function ImageText({ src, title, text }) {
    return (
        <div className="functions">
            <picture>
                <source srcset={src} type="image/png"></source>
                <img className="functions__image" src={src} alt=""></img>
            </picture>
            <span className="functions__title">{title}</span>
            <span className="functions__text">{text}</span>
        </div>
    );
}
export default ImageText;