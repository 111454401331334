import { useState } from 'react';
import BurgerMenuIcon from '../../assets/burger-menu/burger_menu_item.png';
import BurgerMenuCloseIcon from '../../assets/burger-menu/close_icon.png';
import Logo from '../../assets/menu/logos.png';
import Button from '../shared/button/Button';
import Modal from "../shared/modal/Modal";
import styles from './BurgerMenu.module.css';

function BurgerMenu() {
    const [isVisible, setVisible] = useState(false);

    return (
        <>
            <img src={BurgerMenuIcon} alt="menu" onClick={() => setVisible(true)} className={styles.burger} />
            {isVisible && <Modal top>
                <div className={styles.modalCard}>
                    <div className={styles.modalHeader}>
                        <img className={styles.modalLogo} src={Logo} alt="logo" />
                        <img className={styles.modalCloseIcon} src={BurgerMenuCloseIcon} alt="menu" onClick={() => setVisible(false)} />
                    </div>
                    <div className={styles.modalContent}>
                        <div className={styles.innerNavigation}>
                            <a className={styles.navigationItem} onClick={() => setVisible(false)} href="#functions">О сервисе</a>
                            <a className={styles.navigationItem} onClick={() => setVisible(false)} href="#advantages">Преимущества</a>
                            <a className={styles.navigationItem} onClick={() => setVisible(false)} href="#rates">Тарифы</a>
                        </div>
                        <div className={styles.modalButtons}>
                            <Button secondary text={'Зарегистрироваться'} onClick={() => window.location.href = 'https://admin.thebotique.ru/register'} />
                            <Button text={'Войти'} onClick={() => window.location.href = 'https://admin.thebotique.ru/login'} />
                        </div>
                    </div>
                </div>
            </Modal>}
        </>
    );
}

export default BurgerMenu;