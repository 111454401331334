import React from "react";
import PartnerLogo from '../../assets/footer/boxberry_footer_logo.svg';
import plane from "../../assets/footer/plane.svg";
import styles from './Footer.module.css';

function Footer() {

    return (
        <footer className={styles.footer}>
            <div className={styles.footerCol}>
                <div className={styles.footerRow}>
                    <span>Botique</span>
                    <span>2023 © Все права защищены</span>
                </div>
                <div className={styles.footerRow}>
                    <a
                        href={"https://docs.thebotique.ru/license-agreement"}
                        target="_blank"
                    >
                        Лицензионный договор
                    </a>
                    <a href={"https://docs.thebotique.ru/terms-of-use"} target="_blank">
                        Правила использования cервиса
                    </a>
                    <a
                        href={"https://docs.thebotique.ru/privacy-policy"}
                        target="_blank"
                    >
                        Политика обработки персональных данных
                    </a>
                </div>
            </div>
            <div className={styles.footerCol}>
                <div className={styles.partnerText}>
                    Наш партнер
                    <a href="https://boxberry.ru/" target="_blank"><img className={styles.partnerLogo} src={PartnerLogo} alt="partner logo" /></a>
                </div>
            </div>
            <div className={styles.footerCol}>
                <div className={styles.footerRow}>
                    <a href={"mailto:thebotique@mail.ru"} target="_blank">
                        @ Напишите нам
                    </a>
                    <a href={"https://t.me/BotiqueSupport_bot?start"} target="_blank">
                        <img src={plane} alt="" /> Быстрая поддержка
                    </a>
                    <a href={"https://t.me/thebotique_ru"} target="_blank">
                        <img src={plane} alt="" /> Наши новости
                    </a>
                </div>
            </div>
        </footer>
    );
}
export default Footer;