import React from "react";
import styles from './Modal.module.css';

function Modal({ top, children }) {
    return (
        <div className={styles.wrapper} style={top ? { justifyContent: 'flex-start' } : {}}>
            {children}
        </div>
    )
}

export default Modal;