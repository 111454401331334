import React from "react";
import styles from './Advantages.module.css';

function Advantages() {
    const advantagesData = {
        seller: [
            {
                header: 'Увеличение продаж, рост вовлеченности аудитории',
                description: 'Упростите процесс совершения заказа и оплаты покупки при помощи Botique и станьте лидером на рынке',
            },
            {
                header: 'Прозрачная аналитика продаж',
                description: 'Отслеживайте сосояние заказов - исследуйте аналитику и совершенствуйтесь',
            },
            {
                header: 'Постоянно растущая аудитория',
                description: 'Воспользуйтесь преимуществами самого популярного мессенджера с постоянно растущей аудиторией. Достигайте большего количества пользователей и расширяйте аудиторию с каждым днем',
            },
            {
                header: 'Экономия на создании сайта или приложения',
                description: 'Откажитесь от разработки сайта или специального приложения. Мы предлагаем все и сразу в одном месте - в Telegram',
            },
            {
                header: 'Оптимизируйте свою продуктивность',
                description: 'Мы предлагаем эффективные решения для автоматизации рутинных процессов благодаря передовым технологиям и искусственному интеллекту. Управляйте временем с удовольствием вместе с нами',
            },
            {
                header: 'Минимальная комиссия с каждой продажи',
                description: 'Получите максимум прибыли благодаря одной из минимальных комиссий на рынке (только комиссия банка)',
            },
        ],
        customer: [
            {
                header: 'Простота использования и экономия времени',
                description: 'Botique - уникальное пространство с интуитивно-понятным интерфейсом, где клиенты могут при помощи нескольких кликов оформить заказ',
            },
            // {
            //     header: 'Реферальная программа',
            //     description: 'Отслеживайте каждый шаг создания заказа, исследуйте аналитику и совершенствуйтесь',
            // }
        ],
    };
    return (<section className={styles.advantagesWrapper} id="advantages">
        <div className={styles.advantagesHeader}>
            <span className={styles.advantagesHeaderLeft}>Преимущества</span>
            <span className={styles.advantagesHeaderRight}> BOTIQUE</span>
        </div>
        <div className={styles.advantagesList}>
            <div className={styles.advantagesListSubheader}>
                Для продавца:
            </div>
            <div className={styles.advantagesListColumn}>
                <div className={styles.advantagesRow}>
                    {advantagesData.seller.map((advantage, index) => (
                        <div className={styles.advantagesItemWrapper} key={index}>
                            <div className={styles.advantagesItem}>
                                <div className={styles.advantagesItemHeader}>
                                    {advantage.header}
                                </div>
                                <div className={styles.advantagesItemTextWrapper}>
                                    <div className={styles.advantagesItemText}>
                                        {advantage.description}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles.advantagesListSubheader}>
                Для покупателя:
            </div>
            <div className={styles.advantagesListColumn}>
                <div className={styles.advantagesRow}>
                    {advantagesData.customer.map((advantage, index) => (
                        <div className={styles.advantagesItemWrapper} key={index}>
                            <div className={styles.advantagesItem}>
                                <div className={styles.advantagesItemHeader}>
                                    {advantage.header}
                                </div>
                                <div className={styles.advantagesItemTextWrapper}>
                                    <div className={styles.advantagesItemText}>
                                        {advantage.description}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
        {/* <a name="advantages"></a>
        <h2 className="advantages-title"><span>Преимущества</span> Интернет-магазина в telegram</h2>
        <div className="advantages-container">
            {data.map(el => {
                const key = uuid();
                return <div key={key} src={el.src} title={el.title} text={el.text} />
            })}
        </div> */}
    </section >

    )
}
export default Advantages;