import { useEffect, useMemo, useState } from 'react';
import Svg from '../../assets/about/eye-svgrepo-com.svg';
import styles from './About.module.css';

function About() {
    const [scrollY, setScrollY] = useState(0);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const imageStyle = useMemo(() => {
        return {
            transform: `translateX(${scrollY * 0.1 < 100 ? scrollY * 0.1 : 100}px)`, // Изменение положение картинки в зависимости от скролла
        };
    }, [scrollY]);

    function go() {
        window.location.href = 'https://t.me/BotiqueShopBot/shopping?startapp=YMFWXy';
    };

    return (
        <div className={styles.header}>
            <div className={styles.leftPart}>
                <img className={styles.logo} src={require('../../logo.png')} alt="logo" />
                <h1 className={styles.mainHeader}>ВАША СИСТЕМА УПРАВЛЕНИЯ <br /> ПРОДАЖАМИ В TELEGRAM</h1>
                <h4 className={styles.mainSubHeader}>Станьте ближе к клиенту - создайте умный магазин в Telegram</h4>
                <button className={styles.tryButton} onClick={() => { window.location.href = 'https://admin.thebotique.ru' }}>Попробовать</button>
            </div>
            <div className={styles.rightPart}>
                <div className={styles.container}>
                    <img className={styles.imageBotique} src={require('../../assets/about/botique.png')} alt="botique" id="botique-image" style={imageStyle} />
                    <div className={styles.imagePhrase} style={imageStyle}>
                        <div className={styles.phraseText}>Жми на телефон и посмотри работу сервиса</div>
                    </div>

                    {/* <img className={styles.imagePhrase} src={require('../../assets/about/phrase.png')} alt="phrase" style={imageStyle} /> */}
                    {/* <img class={styles.imageCap} src={require('../../assets/about/cap.png')} alt="cap" style={imageStyle} /> */}

                    <div onClick={go} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                        <img className={styles.imagePhone} src={require('../../assets/about/phone.png')} alt="phone" />
                        <div className={styles.imagePhoneBlur} style={{ visibility: isHovered ? 'visible' : 'hidden' }}>
                            <svg width={50} height={50}>
                                <image href={Svg} width={50} height={50} />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )

}
export default About;