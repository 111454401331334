import axios from "axios";
import React, { useState } from "react";
import MaskedInput from "react-text-mask";
import BotiqueImage from '../../assets/contacts/botique.png';
import CloseIcon from '../../assets/contacts/icons-menu-close.svg';
import QuotesImage from '../../assets/contacts/quotes.png';
import Button from "../shared/button/Button";
import Modal from "../shared/modal/Modal";
import styles from './Contacts.module.css';

function hideNotification(callback, params) {
    setTimeout(() => callback(params), 4000);
}

const notificationStatus = { SUCCESS: 0, ERROR: 1 };
const notificationMessages = { [notificationStatus.SUCCESS]: 'Заявка отправлена!', [notificationStatus.ERROR]: 'Произошла ошибка!' }

function Contacts() {
    const phoneNumberMask = ['+', '7', '(', /\d/, /\d/, /\d/, ')', '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/,];

    const [fieldsValueState, setFieldsValueState] = useState({ name: '', telephone: '', text: '' });
    const [filedsErrorState, setFiledsErrorState] = useState({ name: false, telephone: false });
    const [notificationState, setNotificationState] = useState({ status: notificationStatus.SUCCESS, visible: false });

    const [isPendingRequest, setPendingRequest] = useState(false);

    const [checked, setChecked] = useState(true);
    const [isModalVisible, setModalVisible] = useState(false);

    const handleOpenForm = (e) => {
        setModalVisible(true);
        setFieldsValueState({ name: '', telephone: '', text: '' });
        setFiledsErrorState({ name: false, telephone: false });
        setChecked(true);
    }

    const handleChange = (event) => {
        var { name, value } = event.target;

        // eslint-disable-next-line default-case
        switch (true) {
            case name === 'name':
                validateUsername(value) ? setFiledsErrorState({ ...filedsErrorState, ...{ name: false } }) : setFiledsErrorState({ ...filedsErrorState, ...{ name: true } })
                break;
            case name === 'telephone':
                validatePhoneNumber(value) ? setFiledsErrorState({ ...filedsErrorState, ...{ telephone: false } }) : setFiledsErrorState({ ...filedsErrorState, ...{ telephone: true } })
                break;
        }

        if (name === 'telephone' & fieldsValueState.telephone === '' & value === '+7(7__)-___-__-__') {
            value = '+7(___)-___-__-__';
        }

        setFieldsValueState({ ...fieldsValueState, ...{ [name]: value } })
    }

    const handleCloseForm = (e) => {
        setModalVisible(false);
    }

    const validateUsername = (username) => {
        const regex = /^[a-zA-Zа-яА-Я]+$/;
        return regex.test(username);
    }

    const validatePhoneNumber = (phoneNumber) => {
        const regex = /^\+7\(\d{3}\)-\d{3}-\d{2}-\d{2}$/;
        return regex.test(phoneNumber);
    }

    const handleSend = (e) => {
        e.preventDefault();
        console.log(fieldsValueState.name, fieldsValueState.telephone, !filedsErrorState.name, !filedsErrorState.telephone);
        if (fieldsValueState.name && fieldsValueState.telephone && !filedsErrorState.name && !filedsErrorState.telephone) {
            const data = {
                name: fieldsValueState.name,
                tel: fieldsValueState.telephone,
                text: fieldsValueState.text,
            }
            setPendingRequest(true);
            axios.post('https://api.admin.thebotique.ru/api/v1/feedback-request/', data)
                .then((response) => {
                    setPendingRequest(false);
                    setNotificationState({ ...notificationState, ...{ visible: true, status: notificationStatus.SUCCESS } });
                    hideNotification(setNotificationState, { ...notificationState, ...{ visible: false } });
                    handleCloseForm();
                })
                .catch((error) => {
                    setPendingRequest(false);
                    setNotificationState({ ...notificationState, ...{ visible: true, status: notificationStatus.ERROR } });
                    hideNotification(setNotificationState, { ...notificationState, ...{ visible: false } });
                    console.log(error)
                })
        }
    };

    return (
        <section className={styles.contacts}>
            {notificationState.visible && <div className={`${styles.notificationWrapper} ${notificationState.status === notificationStatus.SUCCESS ? styles.notificationWrapperSuccess : styles.notificationWrapperError}`} >
                {notificationMessages[notificationState.status]}
            </div>}
            <div className={styles.contactsBlock}>
                <div className={styles.contactsHeader}>
                    Выйдите на <span className={styles.contactsHeaderHighlight}>новый уровень</span> общения с клиентами
                </div>
                <div className={styles.contactsText}>
                    Создайте свой магазин в Telegram, используя все преимущества сервиса Botique, или оставьте свои данные, и мы с вами свяжемся в самое ближайшее время и ответим на все, интересующие вас вопросы.
                </div>
                <div className={styles.actionButtons}>
                    <Button text={'Создать магазин'} onClick={() => { window.location.href = 'https://admin.thebotique.ru/' }} />
                    <Button text={'Оставить заявку'} onClick={() => handleOpenForm()} secondary />
                </div>
            </div>
            <div className={styles.quoteBlock}>
                <img className={styles.quotesTop} src={QuotesImage} alt="quotes" />
                <img className={styles.quotesBottom} src={QuotesImage} alt="quotes" />
                <div className={styles.quoteText}>
                    “Мы верим, что наш сервис изменит подход к онлайн-шопингу, делая его более персонализированным, быстрым и интерактивным через Telegram”
                </div>
                <div className={styles.quoteAuthors}>
                    <span>
                        Артём и Антон,<br />основатели сервиса Botique
                    </span>
                    <img src={BotiqueImage} alt="botique" />
                </div>
            </div>
            {
                isModalVisible && <Modal>
                    <div className={styles.modalCard}>
                        <div className={styles.modalCardBlur} />
                        <img className={styles.modalCardCloseIcon} src={CloseIcon} alt="Закрыть" onClick={() => handleCloseForm()} />
                        <form onSubmit={(e) => handleSend(e)} className={styles.modalCardContent}>
                            <div className={styles.modalCardHeader}>
                                Оставьте свои данные, и мы свяжемся с вами в самое ближайшее время
                            </div>
                            <div className={styles.modalCardFields}>
                                <input onChange={handleChange} className={styles.input} type="text" name="name" placeholder="Ваше имя" required value={fieldsValueState.name} />
                                {filedsErrorState.name && <span style={{ position: 'fixed', marginTop: '50px' }} className={styles.errorText}>Можно использовать только буквы!</span>}
                                <MaskedInput mask={phoneNumberMask} onChange={handleChange} className={styles.inputTel} type="tel" name="telephone" placeholder="Ваш телефон" required value={fieldsValueState.telephone} />
                                {filedsErrorState.telephone && <span style={{ position: 'fixed', marginTop: '115px' }} className={styles.errorText}>Неправильно введён номер телефона!</span>}
                                <textarea onChange={handleChange} className={styles.textArea} placeholder="Ваш комментарий (необязательно)" maxLength={700} cols="30" rows="5" name="text" value={fieldsValueState.text} />
                                {!isPendingRequest ?
                                    <Button text={'Оставить заявку'} type={'submit'} disabled={!checked} /> :
                                    <div className={styles.loader} />
                                }
                            </div>
                            <div className={styles.modalCardCheckbox}>
                                <input type="checkbox" id="scales" name="scales" checked={checked} onChange={(event) => setChecked(event.target.checked)} />
                                <label for="scales">Оставляя заявку, вы даете согласие на <span className={styles.underlinedText} onClick={() => window.location.href = 'https://docs.thebotique.ru/privacy-policy-agreement'}>обработку своих персональных данных</span></label>
                            </div>
                        </form>
                    </div>
                </Modal>
            }
        </section >
    )
}

export default Contacts;