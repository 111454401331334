import React, { useEffect, useState } from "react";
import { v4 as uuid } from 'uuid';
import Percent from "../../assets/rates/55.png";
import styles from './Rates.module.css';
import axios from "axios";

function Rates() {
    const [price, setPrice] = useState(1999);

    useEffect(() => {
        axios.get('https://api.admin.thebotique.ru/api/v1/get-standart-price').then(r => { setPrice(r.data.price / 100); });
    }, []);

    const ratesData = [
        {
            id: uuid(),
            name: 'Пробный',
            advantages: [
                { id: uuid(), text: '1 Telegram-магазин' },
                { id: uuid(), text: 'Добавление 4 товаров' },
                { id: uuid(), text: 'Простой дизайн' },
                { id: uuid(), text: 'Получение заказов в персональном Telegram-канале (ограничение по количеству заказов - 1 заказ)' },
                { id: uuid(), text: 'Базовая техподдержка' }
            ],
            isDiscounted: false,
            price: {
                main: 'Бесплатно',
                secondary: ''
            }
        },
        {
            id: uuid(),
            name: 'Стандарт',
            advantages: [
                { id: uuid(), text: '1 Telegram-магазин' },
                { id: uuid(), text: 'Количество товаров - не ограничено' },
                { id: uuid(), text: 'Выбор шаблона магазина' },
                { id: uuid(), text: 'Получение заказов в персональном Telegram-канале (нет ограничения по количеству заказов)' },
                { id: uuid(), text: 'Базовая техподдержка' },
            ],
            isDiscounted: true,
            price: {
                main: `${price} руб. / месяц*`,
                // main: '1 руб. / первые 2 недели',
                // secondary: 'далее - 499 руб./месяц*'
            }
        },
        {
            id: uuid(),
            name: 'Индивидуальный',
            advantages: [
                { id: uuid(), text: 'Разработка индивидуального дизайна магазина или выбор готового' },
                { id: uuid(), text: 'Персональный менеджер поддержки с полноценным сопровждением' },
                { id: uuid(), text: 'Доступ к списку посетителей магазина' },
                { id: uuid(), text: 'Таргетированная реклама для покупателей' },
                { id: uuid(), text: 'Участие в программе «маркетплейс Botique»' },
            ],
            isDiscounted: false,
            price: {
                main: 'Стоимость рассчитывается индивидуально'
            }
        }
    ];
    return (
        <section className={styles.ratesPageWrapper} id='rates'>
            <h2 className={styles.ratesPageHeader}>Тарифные планы</h2>
            <div className={styles.ratesRow}>
                {
                    ratesData.map(rate => (
                        <div className={styles.ratesBlock} key={rate.id}>
                            {rate.isDiscounted && <img className={styles.ratePercentIcon} src={Percent} />}
                            <div className={styles.rateHeader}>{rate.name}</div>
                            <div className={styles.rateAdvantages}>
                                {rate.advantages.map(advantage => (
                                    <div className={styles.rateAdvantagesItem} key={advantage.id}>{advantage.text}</div>
                                ))}
                            </div>
                            <div className={styles.ratePrice}>
                                <div className={styles.ratePriceMain}>{rate.price.main}</div>
                                <div className={styles.ratePriceSecondary}>{rate.price.secondary}</div>
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className={styles.ratesFooter}>
                <span>* Ограниченное предложение. Успейте попробовать все преимущества сервиса по сниженной цене!</span>
                {/* <span>Стоимость указанного тарифа будет увеличена в ближайшее время.</span> */}
            </div>
        </section>
    )
}

export default Rates;