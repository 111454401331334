import React from "react";
import { v4 as uuid } from 'uuid';
import '../Functions.css';
import analitics from '../icon-3.png';
import client from '../client.png';
import design from "../quickstart.svg";
import help from '../instruments.png';
import instruments from '../icon-2.png';
import onlinepayment from '../icon.png';
import ImageText from "./ImageText";

function Functions() {
    let data = [{ src: design, title: "Лёгкий старт", text: "Запустите свой умный магазин в несколько кликов" },
    { src: onlinepayment, title: "Умные ИИ-алгоритмы", text: "Генерация контента, поддержка - доверьте это и многое другое алгоритмам" },
    { src: instruments, title: "Техническая поддержка 24/7", text: "Мы придем на помощь, когда будет необходимость" },
    { src: analitics, title: "Гибкая система тарификации", text: "Выбирайте тарифный план, исходя из ваших потребностей" },
    { src: client, title: "Дополнительные возможности для бизнеса", text: "Запускайте рекламные баннеры прямо в Телеграмм-магазине" },
    { src: help, title: "Продуманная кастомизация", text: "Учитывайте все нюансы и разрабатывайте уникальный магазин специально для вашей аудитории  (или клиентской базы)" }];
    return (
        <div className="function">
            <a name="functions"></a>
            <h2 className="functions-title"><span>ЧТО ТАКОЕ</span> BOTIQUE?</h2>
            <div className="functions-frame">
                {data.map(item => {
                    let key = uuid();
                    return <ImageText key={key} src={item.src} title={item.title} text={item.text}></ImageText>
                }
                )}
            </div>
            <button className='functions__btn' onClick={() => { window.location.href = 'https://admin.thebotique.ru' }}>Попробовать</button>
        </div>
    );
}
export default Functions;