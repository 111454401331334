import React, { useState } from 'react';
import Logo from "../assets/menu/logos.png";
import BurgerMenu from './burger-menu/BurgerMenu';

function Menu() {
    const [extra, setExtra] = useState({ style: "" });
    window.addEventListener("scroll", e => {
        setExtra({ style: "menu-scroll" });
    });
    function Go() {
        window.location.href = "https://admin.thebotique.ru/";
    }
    return (
        <nav className={`menu ${extra.style}`}>
            <a href='/'><img className='menu__title' src={Logo}></img></a>
            <ul className="menu__list">
                <li><a className="menu__list__item" href="#functions">О сервисе</a></li>
                <li><a className="menu__list__item" href="#advantages">Преимущества</a></li>
                <li><a className="menu__list__item" href="#rates">Тарифы</a></li>
            </ul>
            <button onClick={Go} className="menu__log-btn">Зарегистрироваться/Войти</button>
            <BurgerMenu />
        </nav>
    )
}
export default Menu;