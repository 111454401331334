import React from "react";
import styles from './Button.module.css';

function Button({ secondary, onClick, text, disabled }) {
    return (
        <div className={styles.wrapper}>
            <button className={secondary ? styles.buttonSecondary : styles.buttonPrimary} disabled={disabled} onClick={(event) => onClick && onClick(event)}>
                {text}
            </button>
        </div>
    )
}

export default Button;