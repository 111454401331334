import React from "react";

function MeaningSection() {
    return (
        <section className="meaning">
            <span className="meaning__title"><span>Bot</span>ique</span>
            <span className="meaning__text"> платформа, которая сочетает в себе систему управления продажами в Telegram и удобный сервис создания магазинов с красивым интерфейсом с возможностью интеграции в Telegram каналы</span>
        </section>
    )
}
export default MeaningSection;