import './App.css';
import Functions from './components/Functions';
import LandingPage from './components/LandingPage';
import MeaningSection from './components/MeaningSection';
import Menu from './components/Menu';
import About from './components/about/About';
import Advantages from './components/advantages/Advantages';
import Contacts from './components/contacts/Contacts';
import Footer from './components/footer/Footer';
import Rates from './components/rates/Rates';
// import Tarif from './components/Tarifs';
// import Videos from './components/Videos';
// import logo from './logo.svg';

function App() {
  document.title = "Botique - это веб-сервис для создания “умных” телеграмм магазинов с готовым сервисом для проведения онлайн-оплаты";
  return (
    <div className='App'>
      <LandingPage>
        <header>
          <Menu></Menu>
          <About></About>
        </header>
        <main>
          <MeaningSection />
          <Functions />
          <Advantages />
          <Rates />
        </main>
        <footer>
          <Contacts />
          <Footer />
        </footer>
      </LandingPage>
    </div>
  );
}

export default App;
